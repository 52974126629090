<template>
    <BT-Blade-Item
        bladeName="order-slot"
        :bladesData="bladesData"
        navigation="order-slots"
        :onNew="newItem"
        title="Order Slot"
        :defaultBladeWidth="700">

        <template v-slot="{ item, data }">
            <v-container>
                <v-row>
                    <v-col cols="auto" align-self="center">
                        <v-icon size="120">mdi-timeline-clock</v-icon>
                    </v-col>
                    <v-col cols="auto">
                         <BT-Field-String
                            v-model="item.slotName"
                            label="Slot Name"
                            :isEditing="data.isEditing || data.isNew" />

                        <BT-Field-Switch
                            v-model="item.isOn"
                            falseText="Off"
                            :isEditing="data.isEditing || data.isNew"
                            label="Status"
                            trueText="On" />

                        <v-row class="my-2">
                            <!-- <BT-Sidebar-List
                                :block="false"
                                buttonClass="mx-1"
                                :itemProperties="['ID','GroupName']"
                                label="Slot Groups"
                                linkGetURL="/get/Links?OrderSlotID={id}"
                                linkNavigation="order-slot-groups"
                                manyText="groupName"
                                manyProp="orderSlotGroupID"
                                manyToMany
                                manyNavigation="order-slot-groups"
                                :searchProps="['groupName']"
                                :singleID="item.id"
                                singleProp="orderSlotID" /> -->

                            <BT-Sidebar-List
                                inline
                                :itemProperties="['ID','Buyer']"
                                label="Customers"
                                linkGetURL="/get/Links?OrderSlotID={id}"
                                linkNavigation="customers"
                                manyText="buyer.companyName"
                                manyProp="supplyAgreementID"
                                manyToMany
                                manyNavigation="customers"
                                :searchProps="['buyer.companyName']"
                                :singleID="item.id"
                                singleProp="orderSlotID"
                                small />
                        </v-row>
                    </v-col>
                    
                </v-row>

                <v-divider class="my-1" />

                <v-row>
                    <v-col cols="12">
                         <BT-Cron
                            v-model="item.cronExpression"
                            :isEditing="data.isEditing || data.isNew"
                            label="Regularity"
                            leadTimeInHours
                            leadTimeLeftLabel="Delivery Lead Time (Hours)"
                            editExpression
                            showExpression />
                    </v-col>
                </v-row>

                <v-row>
                    <v-expansion-panels v-model="panelV">
                        <v-expansion-panel>
                            <v-expansion-panel-header>Adjustments</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <BT-Cron-Adjustments
                                    v-model="item.adjustments"
                                    :cronExpression="item.cronExpression"
                                    leadTimeLeftLabel="Delivery Lead Time (Hours)"
                                    :isEditing="data.isEditing || data.isNew"
                                    leadTimeInHours />
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-row>

            </v-container>

        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Order-Slot',
    components: {
        //BTBladeExpansionLinks: () => import('~components/BT-Blade-Expansion-Links.vue')
        BTSidebarList: () => import('~components/BT-Sidebar-List.vue'),
    },
    props: {
        bladesData: null
    },
    data: function() {
        return {
            panelV: null
        }
    },
    methods: {
        newItem() {
            return {
                slotInterval: 'Once-Off',
                slotFrequency: 1,
                startAfter: this.$BlitzIt.auth.createUTC(),
                stopBefore: this.$BlitzIt.auth.createUTC(),
                slotDay: 1,
                orderSlotGroupID: this.$route.query.groupID
            }
        }
    }
}
</script>